import request from '@/api/request'

// 门店信息查询
export function queryShopInfo(data) {
  return request({
    url: '/external/interface/queryShopInfo?shopId='+data,
    method: 'get',
    data: data
  })
}

// 门店商品分类查询
export function queryProductTypeList(data) {
  return request({
    url: '/external/interface/queryProductTypeList',
    method: 'get',
    params: data
  })
}

// 商品详细信息
export function queryProductById(productId) {
  return request({
    url: '/external/interface/queryProductById?productId='+productId,
    method: 'get'
  })
}

// 门店商品信息查询
export function queryProductList(data) {
  return request({
    url: '/external/interface/queryProductList',
    method: 'get',
    params: data
  })
}

// 门店分类和商品信息查询
export function queryProductTypeGoods(data) {
  return request({
    url: '/external/interface/queryProductTypeGoods',
    method: 'get',
    params: data
  })
}

// 添加会员点餐订单
export function addOrderInfo(data) {
  return request({
    url: '/external/interface/addOrderInfo',
    method: 'post',
    data: data
  })
}

// 添加会员商城订单
export function addMallOrderInfo(data) {
  return request({
    url: '/external/interface/addMallOrderInfo',
    method: 'post',
    data: data
  })
}

// 根据订单号查询订单详细信息
export function queryOrderDetail(data) {
  return request({
    url: '/external/interface/queryOrderDetail?orderCode='+data,
    method: 'get',
  })
}

// 根据会员账号查询所有订单
export function queryOrderByMemberAccount(memberAccount,memberId) {
  return request({
    url: '/external/interface/queryOrderByMemberAccount?memberAccount='+memberAccount+"&memberId="+memberId,
    method: 'get',
  })
}

// 取消、删除订单
export function cancelOrderInfo(data) {
  return request({
    url: '/external/interface/cancelOrderInfo',
    method: 'get',
    params: data
  })
}

// 获取会员OpenId信息
export function getOpenId(data) {
  return request({
    url: '/external/interface/getOpenId',
    method: 'post',
    data: data
  })
}

// 订单支付
export function jsapiPay(data) {
  return request({
    url: '/external/interface/jsapiPay',
    method: 'post',
    data: data
  })
}

// 根据当前shopId和餐桌号查询当前餐桌得订单号
export function queryOrderByShopIdAndCode(data) {
  return request({
    url: '/external/interface/queryOrderByShopIdAndCode',
    method: 'post',
    data: data
  })
}