<template>
    <div class="page">
        <header>
            <!--<div class="title">商城</div>-->
                <van-search :style="{ height: '2.4rem' }" v-model="productParam.productName"
                            shape="round" input-align="center"
                            background="#fff"
                            placeholder="搜索" @click="onSearch"/>
        </header>
        <section>
            <div class="sidebar">
                <div class="sidebar-item" :class="activeIndex == index ? 'active' : ''" @click="handleMenu(index, item)"
                     v-for="(item, index) in menuList" :key="item.key">
                    <img v-show="item.iconUrl" :src="item.iconUrl" alt="">
                    <span>{{ item.title }}</span>
                </div>
            </div>
            <main>
                <p class="menuName">{{ title }}</p>
                <div class="goods">
                    <div class="good" v-for="item in goodsList" :key="item.id" @click="handleGood(item.productId)">
                        <img :src="item.smallImagePath" alt="">
                        <div class="goodinfo">
                            <div class="goodname">
                                <span style="display: none">自营</span>
                                <span>{{item.productName}}</span>
                            </div>
                            <div class="shipping">
                                <span>包邮</span>
                            </div>
                            <div class="price">
                                <span>¥</span>
                                <span>{{item.productPrice}}</span>
                            </div>
                            <div class="curStores">
                                <span>{{item.shopName}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </section>
        <footer>
            <ul class="navFooter">
                <li v-for="(item, index) in navFooterList" :key="item.id" :class="activeFooter == index?'active':''"
                    @click="handleNavFooter(index, item)">
                    <img :src="activeFooter == index?item.iconActiveUrl:item.iconUrl" alt="">
                    <span>{{ item.title }}</span>
                </li>
            </ul>
        </footer>
    </div>
</template>
<script>
    import {queryProductTypeList, queryProductList} from "@/api/order";
    import axios from 'axios'

    export default {
        data() {
            return {
                baseURL:axios.defaults.baseURL,
                active: 0,
                value: '',
                activeFooter: 0,
                constants: {},
                productTypeParam: {
                    dr: 1,
                    type: 2
                },
                productParam: {
                    productStatus: 'Y',
                    dr: 1,
                    typeId:'',
                    productName:''
                },
                title: '人气推荐', // menu 名称
                goodsList: [], // 商品列表
                activeIndex: 0,
                menuList: [],
                navFooterList: [
                    {
                        id: 1,
                        title: '首页',
                        iconUrl: require('./assets/img/home.png'),
                        iconActiveUrl: require('./assets/img/home-active.png'),
                        path: '/home'
                    },
                    {
                        id: 2,
                        title: '商城',
                        iconUrl: require('./assets/img/stores.png'),
                        iconActiveUrl: require('./assets/img/stores-active.png'),
                        path: '/stores'
                    },
                    {
                        id: 3,
                        title: '我的',
                        iconUrl: require('./assets/img/wode.png'),
                        iconActiveUrl: require('./assets/img/wode-active.png'),
                        path: '/wode'
                    },
                ]
            };
        },
        created() {
            this.queryProductTypeList();
        },
        methods: {
            onSearch(){
                if(this.productParam.productName){
                    this.menuList = []
                    this.goodsList = []
                    queryProductList(this.productParam).then(response => {
                        if(response.code==200){
                            response.data.forEach((item) =>{
                                item.smallImagePath = this.baseURL+item.smallImagePath;
                                this.goodsList.push(item)
                            })

                        }
                    });
                }else{
                    this.queryProductTypeList();
                }

            },
            queryProductTypeList() {
                this.menuList = []
                this.goodsList = []
                queryProductTypeList(this.productTypeParam).then(response => {
                    if(response.code==200){
                        response.data.forEach((item,index) =>{
                            if(index<1){
                                item.iconUrl = require('./assets/img/hot.png')
                                this.title = item.productTypeName
                                this.productParam.typeId = item.productTypeId
                            }
                            item.id = item.productTypeId
                            item.title = item.productTypeName
                            this.menuList.push(item)
                        })
                    }
                    this.queryProductList();
                });
            },
            queryProductList() {
                this.goodsList.splice(0,this.goodsList.length)
                queryProductList(this.productParam).then(response => {
                    if(response.code==200){
                        response.data.forEach((item) =>{
                            item.smallImagePath = this.baseURL+item.smallImagePath;
                            this.goodsList.push(item)
                        })

                    }
                });
            },

            handleMenu(index, item) {
                this.activeIndex = index
                this.title = item.title
                this.productParam.typeId = item.id
                this.queryProductList()
            },
            // 尾部导航
            handleNavFooter(index, item) {
                this.activeFooter = index
                console.log('item', item);
                if(item.id!=2){
                    this.$router.push(item.path)
                }

            },
            handleGood(productId) {
                this.$router.push({ name: 'mallInfo', query: { productId: productId }});
            },
        },
        mounted() {
            // 判断当前路径
            let path = this.$route.path
            if (path == '/home') {
                this.activeFooter = 0
            } else if (path == '/stores') {
                this.activeFooter = 1
            } else {
                this.activeFooter = 2
            }
            /*this.initView()*/
        },

    };
</script>
<style scoped lang="css">
    * {
        padding: 0;
        margin: 0;
    }

    html,
    body {
        font-size: 37.5px;
        height: 100%;
    }

    .page {
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    header {
        /* background-color: pink; */
        height: 3.3rem;
        line-height: 3.3rem;
        border-bottom: 1px solid #e6e6e6;
        margin: 0.8rem 0;
    }

    footer {
        height: 3.95rem;
    }

    section {
        flex: 1;
        display: flex;
        overflow: auto;
    }

    .title {
        font-size: 1.33rem;
        height: 3.3rem;
        line-height: 3.3rem;
        color: #333;
        text-align: center;
        border-bottom: 1px solid #e6e6e6;
        margin-bottom: 0.666rem;
    }

    .van-search {
        margin: 0 1.333rem;
    }

    .sidebar {
        width: 8.85rem;
        background-color: #ededed;
    }

    main {
        flex: 1;
        /* background-color: skyblue; */
        padding: 1.22rem 0.96rem 0;
        display: flex;
        flex-direction: column;
    }

    .sidebar-item {
        padding: 1.46rem 1.46rem 1.46rem 0;
        /* padding-right: 1.46rem; */
        font-size: 1rem;
        text-align: right;
        background-color: #ededed;
    }

    .sidebar-item.active {
        background-color: #fff;
    }

    .sidebar-item img {
        width: 1.06rem;
        height: 1.23rem;
        margin-right: 3px;
    }

    .menuName {
        color: #333;
        font-size: 1.06rem;
        height: 1.06rem;
    }

    .goods {
        flex: 1;
        overflow-y: auto;
    }

    .good {
        display: flex;
        padding: 1.2rem 0;
        border-bottom: 1px solid #f3f3f3;
    }

    .good img {
        width: 8rem;
        height: 5.867rem;
        border-radius: 20px;
        margin-right: 20px;
    }

    .goodinfo {
        display: flex;
        flex-direction: column;
    }

    .goodname {
        padding: 0.21rem 0;
        display: flex;
    }

    .goodname span:nth-child(1) {
        display: block;
        padding: 3px 0.32rem;
        font-size: 0.693rem;
        line-height: 0.9rem;
        color: #fff;
        background-color: #fc7a3d;
        border-radius: 3px;
        margin-right: 0.48rem;
    }

    .goodname span:nth-child(2) {
        font-size: 0.8rem;
        color: #333;

    }

    .shipping span {
        display: inline-block;
        font-size: 0.64rem;
        padding: 0.13rem 0.32rem;
        color: #999;
        line-height: 0.9rem;
        margin: 0.25rem 0;
        border: 1px solid #c7c6c6;
        border-radius: 0.9rem;
        box-sizing: border-box;
    }

    .price span {
        display: inline-block;
        color: #d81e06;
        font-weight: bold;
    }

    .price span:nth-child(1) {
        font-size: 0.69rem;
    }

    .price span:nth-child(2) {
        font-size: 1rem;
    }

    .curStores span {
        display: inline-block;
        line-height: 1.01rem;
        padding: 0.21rem 0.53rem;
        font-size: 0.64rem;
        color: #bdbdbd;
        border-radius: 1.01rem;
        background-color: #f5f5f5;
    }

    .navFooter {
        display: flex;
        height: 100%;
    }

    .navFooter li {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .navFooter li.active span {
        color: #fb6d36;
    }

    .navFooter li img {
        width: 1.86rem;
        height: 1.86rem;
    }

    .navFooter li span {
        display: inline-block;
        padding: 0.26rem 0 0 0;
        line-height: 1.28rem;
        font-size: 0.75rem;
        color: #666;
    }

</style>